<template>
  <div class="travel-fields">
    <aRow :gutter="20">
      <aCol :span="12">
        <a-form-item class="travel-input-outer">
          <label for :class="'filled'"> Empresa </label>
          <a-select
            class="travel-input"
            placeholder="Selecione uma empresa"
            optionFilterProp="txt"
            @change="getCompanyBranchesOnChange"
            v-decorator="[
              `company_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
            :disabled="companiesList.length > 0 ? false : true"
          >
            <a-select-option
              v-for="(item, index) of companiesList"
              :key="index"
              :value="item.id"
              :txt="item.trading_name"
            >
              {{ item.id }} - {{ item.trading_name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item class="none">
          <a-input v-decorator="[`company`]" placeholder="Empresa"> </a-input>
        </a-form-item>
      </aCol>

      <aCol :span="12">
        <a-form-item class="travel-input-outer">
          <label for :class="'filled'"> Filial </label>

          <a-select
            class="travel-input"
            placeholder="Selecione uma filial"
            optionFilterProp="txt"
            :disabled="companyBranchesList.length == 0"
            v-decorator="[
              `company_branch_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
            @change="onCompanyBranchesChange"
          >
            <a-select-option
              v-for="(item, index) of companyBranchesList"
              :key="index"
              :value="item.id"
              :txt="item.name"
            >
              {{ item.id }} - {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item class="none">
          <a-input v-decorator="[`company_branch`]" placeholder="Filial">
          </a-input>
        </a-form-item>
      </aCol>

      <aCol class="none" :span="12">
        <a-form-item class="travel-input">
          <label for :class="'filled'">Empresa</label>
          <a-select
            class="travel-input"
            show-search
            placeholder="Selecione"
            v-decorator="['company']"
          >
            <a-select-option
              v-for="(item, index) of companies"
              :key="index.label"
              :value="item.label"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol :span="12">
        <a-form-item class="travel-input">
          <label for :class="'filled'">Consultor responsável</label>
          <a-select
            class="travel-input w100"
            show-search
            placeholder="Selecione"
            optionFilterProp="txt"
            v-decorator="['user']"
            :disabled="users.length > 0 ? false : true"
          >
            <a-select-option
              v-for="(item, index) of users"
              :key="index.label"
              :value="item.id"
              :txt="item.first_name"
            >
              {{ item.id }} - {{ item.first_name }} {{ item.last_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol :span="12">
        <a-form-item class="travel-input-outer">
          <label for :class="'filled'"> Status </label>
          <a-select
            class="travel-input"
            placeholder="Selecione"
            optionFilterProp="txt"
            v-decorator="[
              `status`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) of [
                {
                  label: 'Cancelado',
                  value: 'canceled',
                },
                {
                  label: 'Concluído',
                  value: 'concluded',
                },
                {
                  label: 'Edição',
                  value: 'edit',
                },
              ]"
              :key="index"
              :value="item.value"
              :txt="item.label"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol :span="12">
        <a-form-item class="travel-input">
          <label for :class="'filled'">Data do lançamento</label>
          <a-date-picker
            placeholder="Selecione"
            format="DD/MM/YYYY"
            valueFormat="YYYY-MM-DD"
            :showToday="false"
            :allowClear="true"
            v-mask="'##/##/####'"
            v-decorator="[
              'release_date',
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-date-picker>
        </a-form-item>
      </aCol>

      <aCol :span="12">
        <a-form-item class="travel-input">
          <label for :class="'filled'">Data de criação</label>
          <a-date-picker
            placeholder="Selecione"
            format="DD/MM/YYYY HH:mm"
            valueFormat="YYYY-MM-DD HH:mm"
            :showToday="false"
            :allowClear="true"
            :disabled="true"
            v-mask="'##/##/####'"
            v-decorator="[
              'created',
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-date-picker>
        </a-form-item>
      </aCol>

      <aCol :span="12">
        <a-form-item class="travel-input-outer mb-0">
          <label for :class="'filled'">ID Infotera</label>
          <a-input
            type="number"
            class="travel-input mt-5"
            placeholder="Insira o ID (opcional)"
            v-model="idInfotera"
            :disabled="infoteraLoading || disableInfoterId"
            @keyup.enter="getInfotera"
            @change="onChangeInfoteraId"
          >
            <a-icon slot="prefix" type="field-svg" />
            <a-icon
              class="cgreen"
              v-if="!infoteraLoading"
              slot="suffix"
              @click="onClickGetInfotera"
              type="vertical-align-bottom"
            />
            <a-icon v-if="infoteraLoading" slot="suffix" type="loading" />
          </a-input>
        </a-form-item>
      </aCol>

      <aCol
        v-if="tempContract.status === 'concluded' && $root.isAdmin()"
        :span="12"
      >
        <HayaSelectField
          label="Anti-fraude Manual"
          fieldKey="manual_anti_fraud"
          :required="false"
          :form="form"
          :preIcon="true"
          :list="antiFraudList"
        />
      </aCol>
    </aRow>

    <aRow
      class="mt-20"
      v-if="
        contract.meta.contract_done &&
        $root.isAdmin() &&
        tempContract.sale_status != 'Finalizado'
      "
    >
      <aCol :span="14">
        <a-checkbox
          class="mt-10"
          v-model="allowUserToEdit"
          style="font-size: 13px; font-weight: 500; position: relative"
          @change="onChangeAllowUser"
          :disabled="alowEditLoading"
        >
          Permitir edição do usuário durante
          <b
            >{{ allowMinutes }} minutos.
            <a-icon type="loading" v-if="alowEditLoading"
          /></b>
        </a-checkbox>

        <a-form-item style="display: none">
          <a-input
            v-decorator="[
              'allow_user_edit',
              {
                initialValue: allowUserToEdit,
              },
            ]"
            :value="allowUserToEdit"
          />
        </a-form-item>
      </aCol>
      <aCol :span="4">
        <div>
          <a-input-number
            v-model="allowMinutes"
            :disabled="alowEditLoading"
            :min="20"
            :max="60"
            :step="5"
            @change="onMinutesChange"
          />
        </div>
      </aCol>
      <aCol
        v-if="tempContract['allow_user_edit_expiration_date_time'] != undefined"
        :span="6"
      >
        <div style="position: relative; top: -5px">
          <div style="font-size: 12px; font-weight: 500">Expirará em:</div>
          {{ tempContract["allow_user_edit_expiration_date_time"] }}
        </div>
      </aCol>
    </aRow>

    <aRow v-else>
      <aCol class="mt-20" :span="24">
        <a-alert class="f12" type="info" banner>
          <template slot="message">
            A opção para permitir edição do usuário será disponibilizada após a
            <b>GERAÇÃO DO CONTRATO</b>.
          </template>
        </a-alert>
      </aCol>
    </aRow>

    <div v-if="tempContract.status == 'concluded' && contractHaveSale == false">
      <a-divider></a-divider>

      <a-button @click="createSale" type="primary" :loading="loadingCreateSale">
        Criar venda
      </a-button>
    </div>
  </div>
</template>

<script>
import { addMinutes } from "date-fns";
import formatInfoteraToHaya from "../mixins/formatInfoteraToHaya";
import formatThings from "@/mixins/general/formatThings";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";

export default {
  name: "ContractSettingsModal",
  props: {
    form: Object,
    contract: Object,
    tempContract: Object,
    allowUserToEdit: Boolean,
    disableInfoterId: Boolean,
  },
  components: { HayaSelectField },
  mixins: [formatThings, formatInfoteraToHaya],
  data() {
    return {
      allowMinutes: 20,
      editExpiration: undefined,
      alowEditLoading: false,
      loadingCreateSale: false,
      marketingTypes: [],
      companiesList: [],
      antiFraudList: [],
      contractHaveSale: true,
      companyBranchesList: [],
      companies: [
        {
          label: "Viajar Resorts",
          value: "Viajar Resorts",
        },
        {
          label: "Voe Simples",
          value: "Voe Simples",
        },
        {
          label: "Credimilhas",
          value: "Credimilhas",
        },
      ],
      users: [],
    };
  },
  beforeMount() {
    if (
      this.tempContract.status == "concluded" ||
      this.tempContract.status == "canceled"
    )
      this.onMinutesChange(this.allowMinutes);

    this.$http.get(`/company/list?page=1&per_page=100`).then(({ data }) => {
      this.companiesList = data.data;
    });

    this.$http
      .get("/user/list?page=1&per_page=100")
      .then(({ data }) => {
        this.users = data.data;
        this.form.setFieldsValue({
          [`user`]: this.tempContract["user"],
        });
      })
      .catch(({ response }) => {
        this.$message.error(response.data.message);
      });

    if (this.tempContract.status === "concluded")
      this.$http
        .get(
          `/category-entry/list?page=1&per_page=500&category_id=37&order-by=name&order=ascend`
        )
        .then(({ data }) => {
          this.antiFraudList = data.data.map(({ name }) => {
            return {
              label: `${name}`,
              value: name,
            };
          });
        });

    if (this.tempContract["company_id"] != undefined)
      this.getCompanyBranches(this.tempContract["company_id"]);
  },
  mounted() {
    this.form.setFieldsValue({
      [`release_date`]:
        this.tempContract["release_date"] == "0000-00-00"
          ? undefined
          : this.tempContract["release_date"],
      [`created`]: this.tempContract["created"],
      [`status`]: this.tempContract["status"],
      [`company`]: this.tempContract["company"],
      [`company_branch`]: this.tempContract["company_branch"],
      [`company_id`]: this.tempContract["company_id"],
      [`company_branch_id`]: this.tempContract["company_branch_id"],
      [`manual_anti_fraud`]: this.tempContract["manual_anti_fraud"],
    });

    this.marketingTypes = this.tempContract.marketing_types
      ? JSON.parse(this.tempContract.marketing_types)
      : [];

    this.idInfotera =
      this.tempContract["id_infotera"] != 0
        ? this.tempContract["id_infotera"]
        : undefined;

    this.allowMinutes =
      this.tempContract["allow_user_edit_expiration_minutes"] != undefined
        ? parseInt(this.tempContract["allow_user_edit_expiration_minutes"])
        : 20;

    this.getSales();
  },
  methods: {
    onChangeInfoteraId(e) {
      this.tempContract["id_infotera"] = e.target.value;
    },
    afterFormatInfoteraData() {
      let newContract = {
        ...this.tempContract,
        ...this.infoteraFormattedData,
      };

      this.$emit("updateTempContractFromChild", newContract);

      this.tempContract = newContract;
    },
    onClickGetInfotera() {
      this.getInfotera();
      this.tempContract["id_infotera"] = this.idInfotera;
    },
    getSales() {
      this.$http
        .get(
          `/sale/list?page=1&per_page=10&contract-id=${this.tempContract.id}`
        )
        .then(({ data }) => {
          data.data.length == 1
            ? (this.contractHaveSale = true)
            : (this.contractHaveSale = false);
        })
        .catch(({ response }) => {
          response;
          this.contractHaveSale = false;
        });
    },
    createSale() {
      this.loadingCreateSale = true;
      this.$http
        .post("/sale/create", {
          user_id: this.tempContract.user,
          company_id: this.tempContract.company_id,
          company_branch_id: this.tempContract.company_branch_id,
          contract_id: this.tempContract.id,
          customer_id: this.tempContract.customer_id,
          value: this.tempContract.contract_total_value,
          taxes_value: this.tempContract.contract_taxes_value,
          profit_value: this.tempContract.contract_profit_value,
          value_to_pay: this.tempContract.contract_net_value,
          status: "Revisão",
          passengers: "[]",
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
        })
        .then(({ data }) => {
          this.$message.success(data.message);
          this.loadingCreateSale = false;

          this.getSales();
        });
    },

    onCompanyBranchesChange(id) {
      let companyBranch = this.companyBranchesList.filter((c) => {
        return c.id == id;
      });

      this.form.setFieldsValue({
        [`company_branch`]: companyBranch[0].name,
      });
    },
    getCompanyBranchesOnChange(id) {
      this.form.setFieldsValue({
        [`company_branch_id`]: undefined,
        company_branch: undefined,
      });

      let company = this.companiesList.filter((c) => {
        return c.id == id;
      });

      this.form.setFieldsValue({
        [`company`]: company[0].trading_name,
      });

      this.companyBranchesList = [];
      this.getCompanyBranches(id);
    },
    getCompanyBranches(companyId) {
      this.$http
        .get(
          `/company-branch/list?page=1&per_page=100&status=Ativo&show_in_contract=1&company_id=${companyId}`
        )
        .then(({ data }) => {
          data;
          this.companyBranchesList = data.data;
        })
        .catch(({ response }) => {
          response;
        });
    },
    onMinutesChange(val) {
      let expiration = addMinutes(new Date(), val);

      this.editExpiration = expiration.getTime();
      this.tempContract["allow_user_edit_expiration_time"] =
        expiration.getTime();
      this.tempContract["allow_user_edit_expiration_date_time"] =
        this.formatDateTimeObj(expiration);
      this.tempContract["allow_user_edit_expiration_minutes"] =
        this.allowMinutes;
    },
    onChangeAllowUser(val) {
      let updateContract = {
        id: this.tempContract.id,
        allow_user_edit: val.target.checked ? "1" : "0",
      };

      if (val.target.checked == "1") {
        updateContract.user = this.tempContract.user;
        updateContract.allow_user_edit_expiration_minutes = this.allowMinutes;
        updateContract.allow_user_edit_expiration_date_time =
          this.formatDateTimeObj(this.editExpiration);
        updateContract.allow_user_edit_expiration_time = this.editExpiration;
        updateContract.action = "update-user-permission";
        updateContract.description = `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> liberou a edição para o contrato <font class="id">ID ${this.tempContract.id}</font>. Clique nesta notificação para acessar.`;
        updateContract.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };
      } else {
        updateContract.allow_user_edit_expiration_minutes = "";
        updateContract.allow_user_edit_expiration_date_time = "";
        updateContract.allow_user_edit_expiration_time = "";
      }
      this.alowEditLoading = true;
      this.$http
        .post("/contract/update-multi-meta", updateContract)
        .then(() => {
          this.alowEditLoading = false;
          this.$message.success(
            "Permissões do usuário atualizadas com sucesso!"
          );
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.alowEditLoading = false;
        });
    },
  },
};
</script>
